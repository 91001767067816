import image1 from "../Assets/update-1.jpg";
import image2 from "../Assets/work-2.jpg";
import image3 from "../Assets/work-3.jpg";
import image4 from "../Assets/work-4.jpg"
import image5 from "../Assets/work-5.jpg"

const worksData = [
    {
      id:1,
      firstImage: image1,
      textTitle: 'JAFFA',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.',
      fullText:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem dolor adis aspos.'
    },
    {
      id:2,
      firstImage: image2,
      textTitle: 'FLOIL',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adis ipsum atis lorem ipsum dolor sit amet consectour.',
    },
    {
      id:3,  
      firstImage: image3,
      textTitle: 'FLEXOGRAF',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet',
    },
    {
      id:4,
      firstImage: image4,
      textTitle: 'Work 3',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet',
    },
    {
      id:5,
      firstImage: image5,
      textTitle: 'Work 3',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet',
    },
    // {
    //   id:6,
    //   firstImage: image3,
    //   textTitle: 'Work 3',
    //   description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet',
    // }
  ];

  export { worksData }